<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      hide-default-footer
      disable-pagination
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-row no-gutters class="pt-2">
          <v-col cols="2" class="pl-2">
            <v-datetime-picker
              v-model="dateFrom"
              :label="$lang.labels.from"
              no-title
              :text-field-props="{ outlined: true, dense: true }"
            >
              <template v-slot:dateIcon="{}">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon="{}">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>

          <v-col cols="2" class="pl-2">
            <v-datetime-picker
              v-model="dateTill"
              :label="$lang.labels.to"
              no-title
              :text-field-props="{ outlined: true, dense: true }"
            >
              <template v-slot:dateIcon="{}">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon="{}">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>

          <!-- Right-aligned refresh button -->
          <v-col cols="2" class="ml-auto d-flex justify-end">
            <v-btn
              text
              light
              color="primary"
              class="ml-2"
              @click="sendQuery()"
            >
              <v-icon dense small>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.id="{ item, index }">
        <div class="font-weight-bold"># {{ index + 1 }}</div>
      </template>

      <template v-slot:item.processId="{ item }">
        <div>
          <v-btn
            text
            small
            color="primary"
            @click="goToProcess(item)"
          >{{ item.processId }}</v-btn>
        </div>
      </template>

      <template v-slot:item.count="{ item }">
        <div>{{ item.count }}</div>
      </template>

      <template v-slot:item.counter="{ item }">
        <div>{{ item.counter }}</div>
      </template>

      <template v-slot:item.exception="{ item }">
        <div class="color-primary clickable-simple" style="white-space: nowrap;overflow: hidden;max-width: 300px;text-overflow: ellipsis" @click="!item.exception ? '' : setSelectedEst(item)">
          {{ item.exception }}
        </div>
      </template>

      <template v-slot:item.firstOccurrence="{ item }">
        <div style="white-space: nowrap;overflow: hidden;max-width: 300px;text-overflow: ellipsis">
          {{ item.firstOccurrence | formatDateTimePrecise }}
        </div>
      </template>

      <template v-slot:item.lastOccurrence="{ item }">
        <div style="white-space: nowrap;overflow: hidden;max-width: 300px;text-overflow: ellipsis">
          {{ item.lastOccurrence | formatDateTimePrecise }}
        </div>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackShow"
      :color="snackColor"
      content-class="text-center"
      top
    >
      <span class="color-accent-text">{{ snackbarText }}</span>
    </v-snackbar>
    <v-dialog
      v-if="showSelectedEst"
      v-model="showSelectedEst"
      max-width="71%"
      style="min-height: 80vh; max-height: 80vh"
    >
      <log-est :data="selectedEst" @closeDialog="selectedEst = null; showSelectedEst = false"></log-est>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import LogEst from './LogEst'

export default {
  components: { LogEst },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    tableKey: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      dateFrom: new Date(Date.now() - ( 3600 * 1000 * 24)),
      dateTill: new Date(Date.now() + ( 3600 * 1000 * 3)),
      showSelectedEst: false,
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      options: {},
      totalItems: 0,
      firstOccurrence: null,
      lastOccurrence: null,
      counter: 0,
      headers: [
        { text: this.$lang.header.id, value: 'id', sortable: false },
        { text: this.$lang.labels.processId, value: 'processId', sortable: false },
        { text: this.$lang.labels.count, value: 'count', sortable: false },
        { text: this.$lang.labels.exceptionMessage, value: 'exception', sortable: false },
        { text: this.$lang.labels.firstOccurrence, value: 'firstOccurrence', sortable: false },
        { text: this.$lang.labels.lastOccurrence, value: 'lastOccurrence', sortable: false }
      ],
      lock: false,
      status: 'FAILED',
      selectedEst: null
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        this.sendQuery()
      },
      deep: true
    },
    tableKey: {
      handler () {
        if (this.tableKey === 1) {
          this.lock = false
          this.sendQuery()
        }
      },
      immediate: true
    },
    dateFrom: {
      handler () {
        this.sendQuery()
      }
    },
    dateTill: {
      handler () {
        this.sendQuery()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {
    if (this.$route && this.$route.query) {
      this.lock = false
    } else {
      this.lock = false
    }
  },
  methods: {
    setSelectedEst(item) {
      this.selectedEst = {
        message: item.exception,
        trace: item.exceptionStackTrace
      }
      this.showSelectedEst = true
    },
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    sendQuery() {
      if (!this.lock) {
        this.$emit('fetchLogs', { options: this.options, dateFrom : this.dateFrom, dateTill: this.dateTill })
      }
    },
    goToProcess(item) {
      const routeData = this.$router.resolve({
        name: 'processEdit',
        params: { id: item.processId }
      })

      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  text-decoration: underline;
  color: var(--v-primary-base);
}
</style>
