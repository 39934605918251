<template>
  <div style="width: 100%;" class="custom-container">
    <v-container fluid>
      <v-tabs 
        v-model="tab"
        class="custom-tab-design"
        background-color="transparent"
      >
        <v-tab 
          :key="0" 
          data-cy="tab-all-logs"
          class="ml-2"
        >
          {{ $lang.labels.logs }}
        </v-tab>
        <v-tab 
          :key="1" 
          data-cy="tab-failed-logs"
          class="ml-2"
        >
          {{ $lang.labels.failures }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="fill-height custom-bg pt-6">
        <v-tab-item :key="0" class="fill-height">
          <v-row dense no-gutters>
            <v-col cols="12">
              <Table
                :table-key="tab"
                :items="logs"
                :loading="loading"
                @fetchLogs="fetchLogs($event)"
              ></Table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item :key="1" class="fill-height">
          <v-row dense no-gutters>
            <v-col cols="12">
              <failure-logs
                :table-key="tab"
                :items="failures"
                :loading="loadingFailures"
                @fetchLogs="fetchFailures($event)"
              ></failure-logs>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import Table from './LogsStepDatatable'
import FailureLogs from './FailureLogsDatatable'
import {
  getProcessStepLogsUsingGET as getLogs,
  getProcessStepFailuresUsingGET as getFailures
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: {
    Table,
    FailureLogs
  },
  data() {
    return {
      err: '',
      success: '',
      tab: 0,
      loading: false,
      loadingFailures: false,
      logs: { items: [], meta: {} },
      failures: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    fetchFailures(options) {
      this.loadingFailures = true
      getFailures({ dateFrom: options.dateFrom, dateTill: options.dateTill })
        .then((res) => {
          this.failures = res.data.data
          this.loadingFailures = false
        })
        .catch((err) => {
          const errorMessage = err.response?.data?.message || 'Something went wrong'

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.loadingFailures = false
        })
    },
    fetchLogs(options) {
      this.err = ''
      if (options) {
        if (options.dateFrom > options.dateTill) {
          this.addSnackbar({
            message: this.$lang.errors.dateWrong,
            timeout: 5000,
            color: 'error'
          })

          return
        }
      }

      const obj = {
        dateFrom: options ? options.dateFrom : '',
        dateTill: options ? options.dateTill : ''
      }

      if (options) {
        if (this.tab === 0 && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page
          obj.size = options.options.itemsPerPage
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options?.options?.sortDesc && options.options.sortDesc.length > 0) obj.orderDirection = options.options.sortDesc[0] ? 'DESC' : 'ASC'

      if (options && options.options && options.options.sortBy && options.options.sortBy.length > 0) {
        obj.orderField = options.options.sortBy[0]
      }

      if (options && options.processId) {
        obj.processId = options.processId
        if ([null, undefined, 'null', 'undefined', '', 0].includes(obj.processId)) delete obj.processId
      }

      if (options && options.eventId) {
        obj.eventId = options.eventId
      }

      if (options && options.stepId) {
        obj.stepId = options.stepId
      }

      if (options && options.stepType) {
        obj.stepType = options.stepType
      }

      if (options && options.statusType) {
        obj.status = options.statusType
      }

      this.loading = true
      getLogs(obj)
        .then((res) => {
          this.logs = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
          console.log(error)
        })
    }
  }
}
</script>
